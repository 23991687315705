/* Styling for components to open the omnisearchbox */
.omnisearch-trigger-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.omnisearch-trigger-row input {
  flex: 1;
  padding: 0.5rem;
  width: 18rem;
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  background-color: rgb(var(--textbox-bg-rgb));
  color: rgb(var(--foreground-rgb));
}

.omnisearch-trigger-row input:focus {
  outline: none;
  border-color: rgba(var(--foreground-rgb), 0.4);
}

/* Modal overlay */
.omnisearch-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container */
.omnisearch-modal {
  background-color: rgb(var(--background-rgb));
  padding: 20px;
  position: relative;
  border-radius: 8px;
  width: 90%;
  height: 60vh;
  max-width: 800px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .omnisearch-modal {
    width: 100%;
    border-radius: 0;
    position: fixed;

    /* This allowed us to still show the navbar, but it
    turns out because of the keyboard, on mobile we need
    all the real estate possible.

    top: var(--navbar-height, 0px);
    height: calc(100vh - var(--navbar-height, 0px));
    max-height: calc(100vh - var(--navbar-height, 0px));
    */

    top: 0;
    height: 80vh;
    max-height: 90vh;

    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
}

/* Search container */
.omnisearch-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Input row container */
.omnisearch-input-row {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.omnisearch-input-row .compose-input-container {
  flex: 1;
}

/* Results container */

.omnisearch-results {
  flex: 1;
  overflow-y: auto;
  border: 1px solid rgba(var(--foreground-rgb), 0.2);
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: rgb(var(--textbox-bg-rgb));
}

/* Individual result items */
.omnisearch-result {
  padding: 1rem;
  margin-right: 2rem;
  cursor: pointer;
  transition: background-color 0.2s;
  color: rgb(var(--foreground-rgb));
}

.omnisearch-result-name {
  font-weight: 700;
}

.omnisearch-result-description {
  color: rgba(var(--foreground-rgb), 0.7);
  font-size: 0.8em;
}

.omnisearch-result:not(.post-question):hover {
  background-color: rgba(var(--foreground-rgb), 0.1);
}

.omnisearch-result.post-question {
  margin-top: 1rem;
  padding-top: 1rem;
  color: rgb(var(--accent-rgb));
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.omnisearch-result.post-question.has-results {
  border-top: 1px solid rgba(var(--foreground-rgb), 0.2);
}

.post-controls {
  display: flex;
  gap: 2rem;
  align-items: center;
  position: relative;
}

/* Position the visibility dropdown for OmniSearchBox */
.post-controls .visibility-dropdown.below {
  top: 100%;
  bottom: auto;
  right: auto;
  left: 0;
  margin-top: 0.5rem;
}

.post-controls .controls {
  padding: 0.3rem 2rem;
  border-radius: 0.3rem;
  background: rgb(var(--button-bg-rgb));
  border: none;
  color: inherit;
  cursor: pointer;
}

.post-controls .controls:hover {
  background: rgb(var(--button-hover-bg-rgb));
}

/* Loading and empty states */
.omnisearch-message {
  padding: 0.5rem;
  color: rgb(var(--foreground-rgb));
  text-align: left;
}

.omnisearch-examples {
  list-style-type: none;
  margin: 0.5rem 0 1rem 1.5rem;
  padding: 0;
}

.omnisearch-examples li.omnisearch-result {
  display: list-item;
  padding: 0.25rem 0;
}

.omnisearch-close {
  background: none;
  border: none;
  color: rgb(var(--foreground-rgb));
  cursor: pointer;
  padding: 8px;
  font-size: 1.2em;
  opacity: 0.7;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.omnisearch-close:hover {
  opacity: 1;
}
